<template>
   <div>
        <b-modal id="districtModalEdit" title="Изменить данные района" size="md" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="opened">
            <b-row>
                <b-col cols="12">
                        <b-row>
                        <b-col cols="6">
                            <label>Ваш район</label>
                            <b-input v-model="district.name" placeholder="Район" />
                        </b-col>
                        <b-col cols="6">
                            <label>Выберите свою зону</label>
                            <v-select
                                v-model="district.zone_id"
                                placeholder="Зона"
                                label="name"
                                :options="zones"
                                :reduce="options => options.id"
                                class="select-size-md"
                            />
                        </b-col>
                    </b-row>
                    <b-row class="mt-2">
                        <b-col cols="6">
                            <label>Ваше население</label>
                            <v-select
                                v-model="district.village_id"
                                placeholder="Население пункт"
                                label="name"
                                :options="villages"
                                :reduce="options => options.id"
                                class="select-size-md"
                            />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" class="d-flex justify-content-between">
                            <b-button
                            :disabled="submitButtonDisabled"
                            class="mt-2 col-md-5"
                            variant="primary"
                            @click="editDistrict()">
                                Изменить
                            </b-button>
                            <b-button class="mt-2 col-md-5" variant="secondary" @click="btnCancel">
                                Отмена
                            </b-button>
                        </b-col>
                    </b-row>
                    
                </b-col>
            </b-row>
        </b-modal>
   </div>
</template>
<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
    props: ['id','villages'],
    components: {
        vSelect,
        ToastificationContent,
    },
    data(){
        return {
            district: [],
            submitButtonDisabled: false,
            zones: undefined,
        }
    },
    methods: {
        opened(){
            this.$http
                .get(`addresses/districts/${this.id.id}/edit`)
                .then(res => {
                    this.district = res.data
            })

            this.$http
                .get(`addresses/zones`)
                .then(res => {
                    this.zones = res.data
            })
        },
        editDistrict(){
            this.submitButtonDisabled = true
            this.$http
                .patch(`addresses/districts/${this.id.id}`, this.district)
                .then(res => {
                    this.btnCancel()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: `Район успешно изменен!`,
                        },
                    })
                    this.$emit('refresh')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        btnCancel(){
            this.$bvModal.hide('districtModalEdit')
        }
    }

}
</script>