<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else>
            <table-district :districts="districts" @editForm="editForm" @refresh="refresh" />
        </div>
        <modal-district @refresh="refresh" :villages="villages" />
        <modal-district-edit :id="id" @refresh="refresh" :villages="villages" />
        <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    </div>
</template>
<script>
import tableDistrict from "@/views/component/Table/tableDistrict.vue"
import modalDistrict from "@/views/component/Modal/ModalDistrict/modalDistrict.vue"
import modalDistrictEdit from "@/views/component/Modal/ModalDistrict/modalDistrictEdit.vue"
export default {
    components: {
        tableDistrict,
        modalDistrict,
        modalDistrictEdit,
    },
    data(){
        return {
            id: '',
            districts: [],
            villages: [],
            showPreloader: false,
            fields: [ 
                { key: 'checkbox', label: '', thStyle: {width: '10px'}},
                { key: 'id', label: 'Id', sortable: true, thStyle: {width: '20px'}},
                { key: 'name', label: 'Район', sortable: true, thStyle: {width: '150px'}},
      ],
        }
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        this.openFilter()
        this.village()
    },
    methods: {
        editForm(id){
            this.id = id
            setTimeout(this.openModal, 0);
        },
        openModal(){
            this.$bvModal.show('districtModalEdit')
        },
        openFilter(){
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true) {
                this.showPreloader = true
                this.$http
                    .get(`${this.$route.name}`, {params: param}).then(res => {
                        this.districts = res.data
                        this.$store.commit('pageData/setdataCount', this.districts.length)
                        this.showPreloader = false
                        this.$store.commit('REFRESH_DATA', false)
                    })
            }else if(arrayRoutes.includes(this.$route.path) == false){
                this.$bvModal.show(this.$route.name + 'filter')
            }
        },
        refresh(){
            this.showPreloader = true
            this.$http
                .get('addresses/districts').then(res => {
                    this.districts = res.data
                    this.$store.commit('pageData/setdataCount', this.districts.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                })
        },
        village() {
            this.$http
                .get('addresses/villages')
                .then((res) => {
                    console.log(res.data);
                    this.villages = res.data
                })
        },
        sendToParent(tableData){
            this.districts = tableData
            this.$store.commit('pageData/setdataCount', this.districts.length)
        },
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            if (val){
                this.$http
                .get(`${this.$route.name}`)
                .then(res =>{
                    this.districts = res.data
                    this.$store.commit('pageData/setdataCount', this.districts.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
                }
            }
    }
}
</script>